import { useEffect, useState } from "react";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import WishFileControl from "../../../components/WishFormComponents/WishFileControl";
import useEnrollment from "../../../services/useEnrollment";
import WishDateControl from "../../../components/WishFormComponents/WishDateControl";
import WishSelect from "../../../components/WishFormComponents/WishSelect";
import moment from "moment";

export default function CoApplicantDetailsPage({ enrollmentMasterData, setIsPageDirty, ErrorSection, enrollmentDetails, setEnrollmentDetails }) {
  const { getBankBranchDetails, getCoAppRelationships } = useEnrollment();
  const [relationships, setRelationships] = useState([]);

  useEffect(() => {
    getCoAppRelationships(enrollmentDetails.basicDetails?.gender_id, enrollmentDetails.basicDetails?.marital_status_id, (data) => {
      setRelationships(data.relationships);
      setEnrollmentDetails({
        ...enrollmentDetails,
        coApplicantDetails: {
          ...enrollmentDetails.coApplicantDetails,
          coapplicant_relationship_id: data.relationships[0]?.id,
          nominee_mrelationship_id: enrollmentMasterData.relationship[0]?.id,
        },
      });
    });
  }, []);

  return (
    <div
      onChange={() => {
        setIsPageDirty && setIsPageDirty(true);
      }}
    >
      <WishSingleLineText
        label="Co-Applicant Name"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_name}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_name: newValue },
          });
        }}
      />
      <WishSelect
        label="Co-Applicant Relationship"
        data={relationships}
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_relationship_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_relationship_id: parseInt(newValue) },
          });
        }}
      />
      <WishDateControl
        label="Date of birth"
        id="caDOB"
        initialValue={moment(enrollmentDetails?.coApplicantDetails?.coapplicant_dob).format("YYYY-MM-DD")}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_dob: newValue },
          });
        }}
      />
      <WishSelect
        data={enrollmentMasterData?.gender}
        label="Gender"
        id="caGender"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_gender_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_gender_id: parseInt(newValue) },
          });
        }}
      />
      <WishSingleLineText
        label="Mobile Number"
        id="caMobile"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_phone}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_phone: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Email Address"
        id="caEmail"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_email}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_email: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="PAN Number"
        id="caPan"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_pan_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_pan_no: newValue.toUpperCase().replace(/\s/g, "") },
          });
        }}
      />
      <WishFileControl
        label="PAN Document (attachment)"
        id="caPANDoc"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapp_pan_file?.name}
        onChange={(fileName, fileObject) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapp_pan_file: fileObject },
          });
        }}
      />
      <WishSingleLineText
        label="Account Name"
        id="caAccName"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_account_name}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_account_name: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Account Number"
        id="caAccNumber"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_account_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_account_no: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Confirm Account Number"
        id="caConfirmAccNumber"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_confirm_account_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapplicant_confirm_account_no: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="IFSC Code"
        id="caIFSC"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_ifsc}
        onChange={(newValue) => {
          if (newValue.toUpperCase().replace(/\s/g, "").match("^[A-Z]{4}0[A-Z0-9]{6}$")) {
            getBankBranchDetails(newValue, (data) => {
              setEnrollmentDetails({
                ...enrollmentDetails,
                coApplicantDetails: {
                  ...enrollmentDetails.coApplicantDetails,
                  coapplicant_bank_name: data.bank_name,
                  coapplicant_branch_name: data.branch_name,
                  coapplicant_ifsc: newValue.toUpperCase().replace(/\s/g, ""),
                },
              });
            });
          } else {
            setEnrollmentDetails({
              ...enrollmentDetails,
              coApplicantDetails: {
                ...enrollmentDetails.coApplicantDetails,
                coapplicant_bank_name: "",
                coapplicant_branch_name: "",
                coapplicant_ifsc: newValue.toUpperCase().replace(/\s/g, ""),
              },
            });
          }
        }}
      />
      <WishSingleLineText label="Bank Name" readonly id="caBankName" initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_bank_name} />
      <WishSingleLineText
        label="Bank Branch"
        readonly
        id="caBankBranch"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapplicant_branch_name}
      />
      <WishFileControl
        label="Bank (proof of address)"
        id="caBankDoc"
        initialValue={enrollmentDetails?.coApplicantDetails?.coapp_bank_proof_file?.name}
        onChange={(fileName, fileObject) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, coapp_bank_proof_file: fileObject },
          });
        }}
      />
      <WishSingleLineText
        label="Nominee Name"
        id="caNomineeName"
        initialValue={enrollmentDetails?.coApplicantDetails?.nominee}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, nominee: newValue },
          });
        }}
      />
      <WishSelect
        label="Nominee Relationship"
        data={enrollmentMasterData?.relationship}
        initialValue={enrollmentDetails?.coApplicantDetails?.nominee_mrelationship_id}
        onSelect={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            coApplicantDetails: { ...enrollmentDetails.coApplicantDetails, nominee_mrelationship_id: parseInt(newValue) },
          });
        }}
      />
      <ErrorSection />
    </div>
  );
}
