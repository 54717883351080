import { useEffect, useState } from "react";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import WishFileControl from "../../../components/WishFormComponents/WishFileControl";
import useEnrollment from "../../../services/useEnrollment";
import WishToaster from "../../../components/WishToaster";

export default function BankDetailsPage({ setIsPageDirty, ErrorSection, enrollmentDetails, setEnrollmentDetails }) {
  const { getBankBranchDetails } = useEnrollment();

  return (
    <div
      onChange={() => {
        setIsPageDirty(true);
      }}
    >
      <WishSingleLineText
        label="Account Holder"
        initialValue={enrollmentDetails?.bankDetails?.account_name}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            bankDetails: { ...enrollmentDetails.bankDetails, account_name: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Account Number"
        initialValue={enrollmentDetails?.bankDetails?.account_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            bankDetails: { ...enrollmentDetails.bankDetails, account_no: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="Confirm Account Number"
        initialValue={enrollmentDetails?.bankDetails?.confirm_account_no}
        onChange={(newValue) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            bankDetails: { ...enrollmentDetails.bankDetails, confirm_account_no: newValue },
          });
        }}
      />
      <WishSingleLineText
        label="IFSC Code"
        initialValue={enrollmentDetails?.bankDetails?.ifsc}
        onChange={(newValue) => {
          if (newValue.toUpperCase().replace(/\s/g, "").match("^[A-Z]{4}0[A-Z0-9]{6}$")) {
            getBankBranchDetails(newValue, (data) => {
              setEnrollmentDetails({
                ...enrollmentDetails,
                bankDetails: {
                  ...enrollmentDetails.bankDetails,
                  bank_name: data.bank_name,
                  branch_name: data.branch_name,
                  ifsc: newValue.toUpperCase().replace(/\s/g, ""),
                },
              });
            });
          } else {
            WishToaster({
              toastMessage: "Enter a valid IFSC code!",
              toastType: "error",
            });
            setEnrollmentDetails({
              ...enrollmentDetails,
              bankDetails: { ...enrollmentDetails.bankDetails, bank_name: "", branch_name: "", ifsc: newValue.toUpperCase().replace(/\s/g, "") },
            });
          }
        }}
      />
      <WishSingleLineText label="Bank Name" readonly initialValue={enrollmentDetails?.bankDetails?.bank_name} />
      <WishSingleLineText label="Bank Branch" readonly initialValue={enrollmentDetails?.bankDetails?.branch_name} />
      <WishFileControl
        label="Bank (proof of address)"
        initialValue={enrollmentDetails?.bankDetails?.bank_proof_file?.name}
        onChange={(fileName, fileObject) => {
          setEnrollmentDetails({
            ...enrollmentDetails,
            bankDetails: { ...enrollmentDetails.bankDetails, bank_proof_file: fileObject },
          });
        }}
      />
      <ErrorSection />
    </div>
  );
}
