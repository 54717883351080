import { useState, useEffect } from "react";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "../services/AppUtils";
import useAPIs from "./useAPIs";

const distFromLocal = localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor")) : null;

export const PersonalDetails = {
  distributor_id: distFromLocal?.distributor_id,
  section_level: 1,
  dist_temp_id: "",
  title_id: 1,
  first_name: (String, ""),
  second_name: (String, ""),
  dob: (String, ""),
  language_id: 1,
  gender_id: 1,
  marital_status_id: 1,
  profession_id: 1,
  monthly_income_id: 1,
  aadhar_no: (String, ""),
  aadhar_file: (String, ""),
  pan_no: (String, ""),
  pan_file: (String, ""),
  gst_no: (String, ""),
  pan_declaration: 0,
  gst_declaration: 0,
};

export const ContactDetails = {
  distributor_id: distFromLocal?.distributor_id,
  section_level: 2,
  dist_temp_id: "",
  email: "",
  phone: "",
  permanent_address_line1: "",
  permanent_address_line2: "",
  permanent_postalcode_id: "",
  permanent_postalcode: "",
  permanent_post_name: "",
  permanent_city_id: "",
  permanent_district_id: "",
  permanent_state_id: "",
  permanent_country_id: "",
  permanent_city_name: "",
  permanent_district_name: "",
  permanent_state_name: "",
  permanent_country_name: "",
  is_addresses_same: 1,
  communication_address_line1: "",
  communication_address_line2: "",
  communication_postalcode_id: "",
  communication_postalcode: "",
  communication_post_name: "",
  communication_city_id: "",
  communication_district_id: "",
  communication_state_id: "",
  communication_country_id: "",
  communication_city_name: "",
  communication_district_name: "",
  communication_state_name: "",
  communication_country_name: "",
  address_proof_file: "",
};

export const BankDetails = {
  distributor_id: distFromLocal?.distributor_id,
  section_level: 3,
  dist_temp_id: "",
  bank_name: "",
  branch_name: "",
  ifsc: "",
  account_no: "",
  confirm_account_no: "",
  account_name: "",
  bank_id: 1,
  bank_proof_file: "",
};

export const CoAppDetails = {
  distributor_id: distFromLocal?.distributor_id,
  section_level: 4,
  dist_temp_id: "",
  coapplicant_name: "",
  coapplicant_phone: "",
  coapplicant_email: "",
  coapplicant_dob: "",
  coapplicant_gender_id: 1,
  coapplicant_relationship_id: "",
  coapplicant_pan_no: "",
  coapplicant_bank_name: "",
  coapplicant_branch_name: "",
  coapplicant_ifsc: "",
  coapplicant_account_no: "",
  coapplicant_confirm_account_no: "",
  coapplicant_account_name: "",
  coapplicant_bank_id: 1,
  nominee: "",
  nominee_mrelationship_id: "",
  coapp_pan_file: "",
  coapp_bank_proof_file: "",
};

export const PlacementDetails = {
  distributor_id: distFromLocal?.distributor_id,
  placement_distributor_id: "",
  placement_position_id: "",
  sponsor_distributor_id: distFromLocal?.distributor_id,
  sponsor_distributor_name: distFromLocal?.name,
};

const useEnrollment = (language_id) => {
  const { apiError, processing, postData, postFormData, getData, getExternalData } = useAPIs();
  const [enrollmentMasterData, setEnrollmentMasterData] = useState(null);
  const [enrollmentError, setError] = useState(null);
  const [terms, setTerms] = useState({});
  const [enrollmentLoading, setLoading] = useState(false);
  const [locationDetails, setLocationDetaills] = useState(null);
  const [tempDistId, setTempDistId] = useState("");
  const [currentSection, setCurrentSection] = useState(1);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  useEffect(() => {
    setLoading(processing);
  }, [processing]);

  useEffect(() => {
    if (language_id) {
      // Load all init data here
      getData("/enrollment/link/fetch-enrolment-form-master-data", (data) => {
        setEnrollmentMasterData(data);
      });
      if (language_id) {
        getData(`/enrollment/link/fetch-terms-and-conditions?language_id=${language_id}`, (data) => {
          setTerms(data);
        });
      }
    }
  }, [language_id]);

  useEffect(() => {
    if (enrollmentError) {
      //setLoading(false);
    }
  }, [enrollmentError]);

  const getLocationDetails = (pincode, onSuccess) => {
    //setLoading(true);
    getData(`/sales/v1/bo/location/list/${pincode}`, (locationDetails) => {
      //setLoading(false);
      setLocationDetaills(locationDetails);
      onSuccess(locationDetails);
    });
  };

  const saveEnrolleeDetails = (link_id, pageNumber, payload, onSuccess) => {
    setLoading(true);
    let formData;
    const pages = ["personal-details", "contact-details", "bank-details", "coapp-details"];
    switch (pageNumber) {
      case 0:
        payload.basicDetails.pan_declaration = payload.basicDetails.pan_declaration ? 1 : 0;
        payload.basicDetails.gst_declaration = payload.basicDetails.gst_declaration ? 1 : 0;
        payload.basicDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.basicDetails);
        break;
      case 1:
        payload.contactDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.contactDetails);
        break;
      case 2:
        payload.bankDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.bankDetails);
        break;
      case 3:
        payload.coApplicantDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.coApplicantDetails);
        break;
      default:
        break;
    }

    let savesection = {
      link_id: link_id,
      distributor_id: payload?.basicDetails?.distributor_id,
      dist_temp_id: tempDistId,
      section_level: currentSection,
    };
    //alert(personalDetailsFormData.get("marital_status_id"));
    if (pageNumber < 4) {
      postFormData(
        `/enrollment/link/store-temp-${pages[pageNumber]}`,
        formData,
        (data) => {
          //alert(data.dist_temp_id);
          if (pageNumber === 0) {
            setTempDistId(data.dist_temp_id);
            savesection.dist_temp_id = data.dist_temp_id;
            saveSectionDone(pageNumber, savesection);
          } else {
            saveSectionDone(pageNumber, savesection);
          }
          onSuccess(data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      onSuccess();
      setLoading(false);
      saveSectionDone(pageNumber, savesection);
    }
  };

  const saveSectionDone = (pageNumber, savesection) => {
    console.log(pageNumber, currentSection);
    if (savesection.dist_temp_id && pageNumber < 5 && pageNumber === currentSection - 1) {
      postData(`/enrollment/link/store-temp-enrollee-section-level`, savesection, (data) => {
        console.log(data);
        setCurrentSection(currentSection + 1);
      });
    }
  };

  const getBankBranchDetails = (ifscCode, onSuccess) => {
    getData(`/enrollment/link/fetch-bank-details?ifsc_code=${ifscCode}`, (data) => {
      onSuccess(data);
    });
  };
  const getCoAppRelationships = (genderId, maritialStatusId, onSuccess) => {
    getData(`/enrollment/link/fetch-coapp-relationships-list?gender_id=${genderId}&marital_status_id=${maritialStatusId}`, (data) => {
      onSuccess(data);
    });
  };
  const acceptTerms = (payload = {}, onSuccess) => {
    payload.dist_temp_id = tempDistId;
    postData(`/enrollment/link/accept-terms-and-conditions`, payload, (data) => {
      onSuccess(data);
    });
  };
  const downloadTerms = (payload = {}) => {
    payload.dist_temp_id = tempDistId;
    postData(
      `/enrollment/link/download-dist-contract`,
      payload,
      (data) => {
        window.open(data.contract_file_url);
      },
      (error) => {
        WishToaster({
          toastMessage: "Something went wrong!",
          toastType: "error",
        });
      }
    );
  };
  const validatePostion = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/link/validate-enrollment-position`,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const enrollUser = (payload, onSuccess, onError) => {
    payload.dist_temp_id = tempDistId;
    postData(
      `/enrollment/link/create-new-distributor`,
      payload,
      (data) => {
        onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
      }
    );
  };
  const fetchLinkDetails = (link_id, onSuccess, onError) => {
    postData(
      `/enrollment/link/fetch-enrolment-link-details`,
      {
        link_id: link_id,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const fetchEnrolleeDetails = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/link/fetch-temp-enrollee-details`,
      payload,
      (data) => {
        setTempDistId(data.dist_temp_id);
        setCurrentSection(data.section_level + 1);
        onSuccess(data);
      },
      (error) => {
        onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  return {
    enrollmentError,
    enrollmentLoading,
    enrollmentMasterData,
    terms,
    getLocationDetails,
    saveEnrolleeDetails,
    tempDistId,
    getBankBranchDetails,
    getCoAppRelationships,
    acceptTerms,
    downloadTerms,
    validatePostion,
    enrollUser,
    fetchEnrolleeDetails,
    fetchLinkDetails,
  };
};

export default useEnrollment;
