import { useEffect, useState } from "react";
import WishSingleLineText from "../../../components/WishFormComponents/WishSingleLineText";
import WishFileControl from "../../../components/WishFormComponents/WishFileControl";
import WishFormSection from "../../../components/WishFormComponents/WishFormSection";
import WishFlexBox from "../../../components/WishFlexBox";
import WishCheckBox from "../../../components/WishFormComponents/WishCheckBox";
import ListGroup from "../../../components/ListGroup";
import WishModal from "../../../components/WishModal";
import CommonAPIs from "../../../services/commonAPIs";

export default function ContactDetailsPage({ enrollmentMasterData, setIsPageDirty, ErrorSection, enrollmentDetails, setEnrollmentDetails }) {
  const { fetchLocationsFromPincode } = CommonAPIs();
  const [validPincode, setValidPincode] = useState(0);
  const [pincode, setPincode] = useState(0);
  const [modalId, setModalId] = useState(true);
  const [locations, setLocations] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);

  useEffect(() => {
    if (enrollmentDetails?.contactDetails?.is_addresses_same) {
      setEnrollmentDetails((prevState) => ({
        ...prevState,
        contactDetails: {
          ...prevState.contactDetails,
          communication_address_line1: prevState.contactDetails.permanent_address_line1,
          communication_address_line2: prevState.contactDetails.permanent_address_line2,
          communication_postalcode_id: prevState.contactDetails.permanent_postalcode_id,
          communication_postalcode: prevState.contactDetails.permanent_postalcode,
          communication_post_name: prevState.contactDetails.permanent_post_name,
          communication_city_id: prevState.contactDetails.permanent_city_id,
          communication_city_name: prevState.contactDetails.permanent_city_name,
          communication_district_id: prevState.contactDetails.permanent_district_id,
          communication_district_name: prevState.contactDetails.permanent_district_name,
          communication_state_id: prevState.contactDetails.permanent_state_id,
          communication_state_name: prevState.contactDetails.permanent_state_name,
          communication_country_id: prevState.contactDetails.permanent_country_id,
          communication_country_name: prevState.contactDetails.permanent_country_name,
        },
      }));
    }
  }, [enrollmentDetails?.contactDetails?.is_addresses_same]);

  const validatePincode = function (pincode) {
    if (pincode !== "") {
      const regex = new RegExp("^\\d{6}$");
      console.log(pincode + ", " + regex.test(pincode));
      setValidPincode(regex.test(pincode) === true ? 2 : 1);
      setPincode(pincode);
    } else {
      setValidPincode(0);
      setShowSearchResults(false);
    }
    return validPincode;
  };

  const selectPincode = (item) => {
    console.log(item);
    if (enrollmentDetails?.contactDetails?.is_addresses_same) {
      setEnrollmentDetails((prevState) => ({
        ...prevState,
        contactDetails: {
          ...prevState.contactDetails,
          permanent_postalcode_id: item.postalcode_id,
          permanent_postalcode: item.postalcode,
          permanent_post_name: item.post_name,
          permanent_city_id: item.city_id,
          permanent_city_name: item.city_name,
          permanent_district_id: item.district_id,
          permanent_district_name: item.district_name,
          permanent_state_id: item.state_id,
          permanent_state_name: item.state_name,
          permanent_country_id: item.country_id,
          permanent_country_name: item.country_name,
          communication_postalcode_id: item.postalcode_id,
          communication_postalcode: item.postalcode,
          communication_post_name: item.post_name,
          communication_city_id: item.city_id,
          communication_city_name: item.city_name,
          communication_district_id: item.district_id,
          communication_district_name: item.district_name,
          communication_state_id: item.state_id,
          communication_state_name: item.state_name,
          communication_country_id: item.country_id,
          communication_country_name: item.country_name,
        },
      }));
    } else if (!enrollmentDetails?.contactDetails?.is_addresses_same && modalId) {
      setEnrollmentDetails((prevState) => ({
        ...prevState,
        contactDetails: {
          ...prevState.contactDetails,
          permanent_postalcode_id: item.postalcode_id,
          permanent_postalcode: item.postalcode,
          permanent_post_name: item.post_name,
          permanent_city_id: item.city_id,
          permanent_city_name: item.city_name,
          permanent_district_id: item.district_id,
          permanent_district_name: item.district_name,
          permanent_state_id: item.state_id,
          permanent_state_name: item.state_name,
          permanent_country_id: item.country_id,
          permanent_country_name: item.country_name,
        },
      }));
    } else {
      setEnrollmentDetails((prevState) => ({
        ...prevState,
        contactDetails: {
          ...prevState.contactDetails,
          communication_postalcode_id: item.postalcode_id,
          communication_postalcode: item.postalcode,
          communication_post_name: item.post_name,
          communication_city_id: item.city_id,
          communication_city_name: item.city_name,
          communication_district_id: item.district_id,
          communication_district_name: item.district_name,
          communication_state_id: item.state_id,
          communication_state_name: item.state_name,
          communication_country_id: item.country_id,
          communication_country_name: item.country_name,
        },
      }));
    }
  };

  const selectLocationModal = function () {
    return (
      <WishModal id="dlgSelectLocation" title="Select Location" finishTitle="Select">
        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <div className="col-9">
                <input
                  id="txtPincode"
                  name="txtPincode"
                  type="text"
                  className="form-control"
                  placeholder="Pincode"
                  defaultValue=""
                  onChange={(e) => validatePincode(e.target.value)}
                />
                <small className={validPincode === 0 || validPincode === 2 ? "hidden" : "text-danger"}>Invalid pincode</small>
              </div>
              <div className="col-3">
                <button
                  disabled={!(validPincode === 2)}
                  className="btn btn-block btn-primary"
                  onClick={(e) =>
                    fetchLocationsFromPincode(pincode, (data) => {
                      setLocations(data);
                      setShowSearchResults(true);
                      console.log(locations);
                    })
                  }
                >
                  Search
                </button>
              </div>
              <div className={"col-12 pt-2 " + (showSearchResults === true ? "" : "hidden")}>
                <ListGroup items={locations} onSelect={(item) => selectPincode(item)} label="post_name"></ListGroup>
              </div>
            </div>
          </div>
        </div>
      </WishModal>
    );
  };

  return (
    <div
      onChange={() => {
        setIsPageDirty(true);
      }}
    >
      <WishSingleLineText label="Full Name" readonly initialValue={enrollmentDetails?.basicDetails?.first_name + " " + enrollmentDetails?.basicDetails?.second_name} />
      <WishSingleLineText
        label="Mobile Number"
        initialValue={enrollmentDetails?.contactDetails?.phone}
        onChange={(newValue) => {
          // updateContactDetails({
          //   ...contactDetails,
          //   phone: newValue,
          // });
          setEnrollmentDetails({
            ...enrollmentDetails,
            contactDetails: { ...enrollmentDetails.contactDetails, phone: newValue.replace(/[^\d]/g, "") },
          });
        }}
      />
      <WishSingleLineText
        label="Email Address"
        initialValue={enrollmentDetails?.contactDetails?.email}
        onChange={(newValue) => {
          // updateContactDetails({
          //   ...contactDetails,
          //   email: newValue,
          // });
          setEnrollmentDetails({
            ...enrollmentDetails,
            contactDetails: { ...enrollmentDetails.contactDetails, email: newValue },
          });
        }}
      />
      <WishFormSection title="Permanent Address" />
      <WishSingleLineText
        label="Address Line 1"
        initialValue={enrollmentDetails?.contactDetails?.permanent_address_line1}
        onChange={(newValue) => {
          // updateContactDetails({
          //   ...contactDetails,
          //   permanent_address_line1: newValue,
          //   communication_address_line1: enrollmentDetails?.contactDetails?.is_addresses_same ? newValue : "",
          // });
          setEnrollmentDetails({
            ...enrollmentDetails,
            contactDetails: {
              ...enrollmentDetails.contactDetails,
              permanent_address_line1: newValue,
              communication_address_line1: enrollmentDetails?.contactDetails?.is_addresses_same ? newValue : "",
            },
          });
        }}
      />
      <WishSingleLineText
        label="Address Line 2"
        initialValue={enrollmentDetails?.contactDetails?.permanent_address_line2}
        onChange={(newValue) => {
          // updateContactDetails({
          //   ...contactDetails,
          //   permanent_address_line2: newValue,
          //   communication_address_line2: enrollmentDetails?.contactDetails?.is_addresses_same ? newValue : "",
          // });
          setEnrollmentDetails({
            ...enrollmentDetails,
            contactDetails: {
              ...enrollmentDetails.contactDetails,
              permanent_address_line2: newValue,
              communication_address_line2: enrollmentDetails?.contactDetails?.is_addresses_same ? newValue : "",
            },
          });
        }}
      />
      <div className="form-group">
        <div className="row">
          <label htmlFor="txtDeliveryLocation" className="col-4 col-form-label">
            Post, City, District
          </label>
          <div
            className="input-group col-8"
            data-toggle="modal"
            data-target="#dlgSelectLocation"
            onClick={() => {
              setModalId(true);
            }}
          >
            <input
              id="txtDeliveryLocation"
              name="txtDeliveryLocation"
              placeholder="Post, City, District"
              type="text"
              className="form-control"
              disabled={true}
              value={
                enrollmentDetails?.contactDetails?.permanent_post_name
                  ? enrollmentDetails?.contactDetails?.permanent_post_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_city_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_district_name
                  : ""
              }
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <a>
                  <i className="las la-map-marker"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <label htmlFor="txtDeliveryLocation" className="col-4 col-form-label">
            State, Country, Postal Code
          </label>
          <div
            className="input-group col-8"
            data-toggle="modal"
            data-target="#dlgSelectLocation"
            onClick={() => {
              setModalId(true);
            }}
          >
            <input
              id="txtDeliveryLocation"
              name="txtDeliveryLocation"
              placeholder="State, Country, Postal Code"
              type="text"
              className="form-control"
              disabled={true}
              value={
                enrollmentDetails?.contactDetails?.permanent_state_name
                  ? enrollmentDetails?.contactDetails?.permanent_state_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_country_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_postalcode
                  : ""
              }
            />
            <div className="input-group-append">
              <div className="input-group-text">
                <a>
                  <i className="las la-map-marker"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WishFileControl
        label="Address Proof"
        initialValue={enrollmentDetails?.contactDetails?.address_proof_file?.name}
        onChange={(fileName, fileObject) => {
          // updateContactDetails({
          //   ...contactDetails,
          //   address_proof_file: fileObject,
          // });
          setEnrollmentDetails({
            ...enrollmentDetails,
            contactDetails: {
              ...enrollmentDetails.contactDetails,
              address_proof_file: fileObject,
            },
          });
        }}
      />
      <WishFormSection
        title={
          <WishFlexBox>
            <span>Communications Address</span>
            <WishCheckBox
              label="Same as Permanent Address"
              initialValue={enrollmentDetails?.contactDetails?.is_addresses_same}
              onChange={(newValue) => {
                // updateContactDetails({
                //   ...contactDetails,
                //   is_addresses_same: newValue ? 1 : 0,
                // });
                setEnrollmentDetails({
                  ...enrollmentDetails,
                  contactDetails: { ...enrollmentDetails.contactDetails, is_addresses_same: newValue ? 1 : 0 },
                });
              }}
            />
          </WishFlexBox>
        }
      />
      {enrollmentDetails?.contactDetails?.is_addresses_same ? (
        <></>
      ) : (
        <>
          <WishSingleLineText
            label="Address Line 1"
            id="caLine1"
            initialValue={enrollmentDetails?.contactDetails?.communication_address_line1}
            onChange={(newValue) => {
              // updateContactDetails({
              //   ...contactDetails,
              //   communication_address_line1: newValue,
              // });
              setEnrollmentDetails({
                ...enrollmentDetails,
                contactDetails: { ...enrollmentDetails.contactDetails, communication_address_line1: newValue },
              });
            }}
          />
          <WishSingleLineText
            label="Address Line 2"
            id="caLine2"
            initialValue={enrollmentDetails?.contactDetails?.communication_address_line2}
            onChange={(newValue) => {
              // updateContactDetails({
              //   ...contactDetails,
              //   communication_address_line2: newValue,
              // });
              setEnrollmentDetails({
                ...enrollmentDetails,
                contactDetails: { ...enrollmentDetails.contactDetails, communication_address_line2: newValue },
              });
            }}
          />
          <div className="form-group">
            <div className="row">
              <label htmlFor="txtDeliveryLocation" className="col-4 col-form-label">
                Post, City, District
              </label>
              <div
                className="input-group col-8"
                data-toggle="modal"
                data-target="#dlgSelectLocation"
                onClick={() => {
                  setModalId(false);
                }}
              >
                <input
                  id="txtDeliveryLocation"
                  name="txtDeliveryLocation"
                  placeholder="Post, City, District"
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={
                    enrollmentDetails?.contactDetails?.communication_post_name
                      ? enrollmentDetails?.contactDetails?.communication_post_name +
                        " " +
                        enrollmentDetails?.contactDetails?.communication_city_name +
                        " " +
                        enrollmentDetails?.contactDetails?.communication_district_name
                      : ""
                  }
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <a>
                      <i className="las la-map-marker"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <label htmlFor="txtDeliveryLocation" className="col-4 col-form-label">
                State, Country, Postal Code
              </label>
              <div
                className="input-group col-8"
                data-toggle="modal"
                data-target="#dlgSelectLocation"
                onClick={() => {
                  setModalId(false);
                }}
              >
                <input
                  id="txtDeliveryLocation"
                  name="txtDeliveryLocation"
                  placeholder="State, Country, Postal Code"
                  type="text"
                  className="form-control"
                  disabled={true}
                  value={
                    enrollmentDetails?.contactDetails?.communication_state_name
                      ? enrollmentDetails?.contactDetails?.communication_state_name +
                        " " +
                        enrollmentDetails?.contactDetails?.communication_country_name +
                        " " +
                        enrollmentDetails?.contactDetails?.communication_postalcode
                      : ""
                  }
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <a>
                      <i className="las la-map-marker"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ErrorSection />
      {selectLocationModal()}
    </div>
  );
}
