export const setDatatoEnrollForm = (data, setEnrollmentDetails, link_id) => {
  setEnrollmentDetails({
    basicDetails: {
      link_id: link_id,
      distributor_id: data?.sponsor_distributor_id,
      section_level: 1,
      dist_temp_id: data?.dist_temp_id,
      title_id: data?.title_id ?? 1,
      first_name: data?.first_name ?? "",
      second_name: data?.second_name ?? "",
      dob: data?.dob ?? "",
      language_id: data?.mlanguage_id ?? 1,
      gender_id: data?.mgender_id ?? 1,
      marital_status_id: data?.mmarital_status_id ?? 2,
      profession_id: data?.mprofession_id ?? 1,
      monthly_income_id: data?.mmonthly_income_id ?? 1,
      aadhar_no: data?.aadhar_no ?? "",
      aadhar_file: data?.aadhar_file_name ?? "",
      pan_no: data?.pan_no ?? "",
      pan_file: data?.pan_file_name ?? "",
      gst_no: data?.gst_no ?? "",
      pan_declaration: data?.pan_declaration,
      gst_declaration: data?.pan_declaration,
    },
    contactDetails: {
      link_id: link_id,
      distributor_id: data?.sponsor_distributor_id,
      section_level: 2,
      dist_temp_id: data?.dist_temp_id,
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      permanent_address_line1: data?.permanent_address_line1 ?? "",
      permanent_address_line2: data?.permanent_address_line2 ?? "",
      permanent_postalcode_id: data?.permanent_mpostalcode_id ?? "",
      permanent_postalcode: data?.permanent_mpostalcode ?? "",
      permanent_post_name: data?.permanent_mpost_name ?? "",
      permanent_city_id: data?.permanent_mcity_id ?? "",
      permanent_district_id: data?.permanent_mdistrict_id ?? "",
      permanent_state_id: data?.permanent_mstate_id ?? "",
      permanent_country_id: data?.permanent_mcountry_id ?? "",
      permanent_city_name: data?.permanent_mcity_name ?? "",
      permanent_district_name: data?.permanent_mdistrict_name ?? "",
      permanent_state_name: data?.permanent_mstate_name ?? "",
      permanent_country_name: data?.permanent_mcountry_name ?? "",
      is_addresses_same: data?.is_addresses_same ?? 1,
      communication_address_line1: data?.communication_address_line1 ?? "",
      communication_address_line2: data?.communication_address_line2 ?? "",
      communication_postalcode_id: data?.communication_mpostalcode_id ?? "",
      communication_postalcode: data?.communication_mpostalcode ?? "",
      communication_post_name: data?.communication_mpost_name ?? "",
      communication_city_id: data?.communication_mcity_id ?? "",
      communication_district_id: data?.communication_mdistrict_id ?? "",
      communication_state_id: data?.communication_mstate_id ?? "",
      communication_country_id: data?.communication_mountry_id ?? "",
      communication_city_name: data?.communication_mcity_name ?? "",
      communication_district_name: data?.communication_mdistrict_name ?? "",
      communication_state_name: data?.communication_mstate_name ?? "",
      communication_country_name: data?.communication_mcountry_name ?? "",
      address_proof_file: data?.address_proof_file_name ?? "",
    },
    bankDetails: {
      link_id: link_id,
      distributor_id: data?.sponsor_distributor_id,
      section_level: 3,
      dist_temp_id: data?.dist_temp_id,
      bank_name: data?.bank_name ?? "",
      branch_name: data?.branch_name ?? "",
      ifsc: data?.ifsc ?? "",
      account_no: data?.account_no ?? "",
      confirm_account_no: data?.account_no ?? "",
      account_name: data?.account_name ?? "",
      bank_id: data?.mbank_id ?? 1,
      bank_proof_file: data?.bank_proof_file_name ?? "",
    },
    coApplicantDetails: {
      link_id: link_id,
      distributor_id: data?.sponsor_distributor_id,
      section_level: 4,
      dist_temp_id: data?.dist_temp_id,
      coapplicant_name: data?.coapplicant_name ?? "",
      coapplicant_phone: data?.coapplicant_phone ?? "",
      coapplicant_email: data?.coapplicant_email ?? "",
      coapplicant_dob: data?.coapplicant_dob ?? "",
      coapplicant_gender_id: data?.coapplicant_mgender_id ?? 1,
      coapplicant_relationship_id: data?.coapplicant_mrelationship_id ?? "",
      coapplicant_pan_no: data?.coapplicant_pan_no ?? "",
      coapplicant_bank_name: data?.coapplicant_bank_name ?? "",
      coapplicant_branch_name: data?.coapplicant_branch_name ?? "",
      coapplicant_ifsc: data?.coapplicant_ifsc ?? "",
      coapplicant_account_no: data?.coapplicant_account_no ?? "",
      coapplicant_confirm_account_no: data?.coapplicant_account_no ?? "",
      coapplicant_account_name: data?.coapplicant_account_name ?? "",
      coapplicant_bank_id: data?.coapplicant_mbank_id ?? 1,
      nominee: data?.nominee ?? "",
      nominee_mrelationship_id: data?.nominee_mrelationship_id ?? "",
      coapp_pan_file: data?.coapplicant_pan_file_name ?? "",
      coapp_bank_proof_file: data?.coapplicant_bank_proof_file_name ?? "",
    },
    placementDetails: {
      link_id: link_id,
      distributor_id: data?.sponsor_distributor_id,
      placement_distributor_id: data?.placement_distributor_id,
      placement_position_id: data?.placement_position_id,
      sponsor_distributor_id: data?.sponsor_distributor_id,
    },
  });
};
