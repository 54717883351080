import { useState, useEffect, useRef } from "react";
import { Redirect, useNavigate, useSearchParams } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import pageConfig from "../../data/config.json";
import WishCarousel from "../../components/WishCarousel";
import WishLinkCard from "../../components/WishLinkCard";
import _ from "lodash";
import useEnrollment, { BankDetails, CoAppDetails, ContactDetails, PersonalDetails, PlacementDetails } from "../../services/useEnrollment";
import WishToaster from "../../components/WishToaster";
import LoadingNote from "../../components/LoadingNote";
import WishPreviewSection from "../../components/WishPreviewSection";
import { AppUtils } from "../../services/AppUtils";
import { ValidationUtils } from "../../services/ValidationUtils";
import BasicDetailsPage from "./components/BasicDetails";
import ContactDetailsPage from "./components/ContactDetails";
import BankDetailsPage from "./components/BankDetails";
import CoApplicantDetailsPage from "./components/CoapplicantDetails";
import { useLocation } from "react-router";
import { setDatatoEnrollForm } from "./components/EnrollmentUtils";

const SwalAlert = withReactContent(Swal);

const initialEnrollmentDetails = {
  basicDetails: _.cloneDeep(PersonalDetails),
  contactDetails: _.cloneDeep(ContactDetails),
  bankDetails: _.cloneDeep(BankDetails),
  coApplicantDetails: _.cloneDeep(CoAppDetails),
  placementDetails: _.cloneDeep(PlacementDetails),
};

const EnrollUser = () => {
  const navigations = ["Applicant Details", "Contact Details", "Bank Details", "Co-Applicant Details", "Preview"];
  const PageTitles = [
    "Primary Applicant | Basic Details",
    "Primary Applicant | Contact Details",
    "Primary Applicant | Bank Details",
    "Co Applicant Details",
    "Preview",
    "Placement Details",
  ];
  const navigate = useNavigate();
  const routerState = useLocation();
  const totalPages = navigations.length;
  const [isPageDirty, setIsPageDirty] = useState(true);
  const [pageError, setPageError] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [canNavigate, setCanNavigate] = useState(false);
  const [enrollmentDetails, setEnrollmentDetails] = useState(initialEnrollmentDetails);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    enrollmentError,
    enrollmentLoading,
    enrollmentMasterData,
    terms,
    saveEnrolleeDetails,
    acceptTerms,
    downloadTerms,
    validatePostion,
    enrollUser,
    fetchEnrolleeDetails,
    fetchLinkDetails,
  } = useEnrollment(1);

  useEffect(() => {
    if (searchParams.get("id")) {
      console.log(searchParams.get("id"), "link id");
      fetchLinkDetails(
        searchParams.get("id"),
        (data) => {
          // setEnrollmentDetails((prevState) => ({
          //   ...prevState,
          //   basicDetails: { ...prevState.basicDetails, distributor_id: data.distributor_id, link_id: searchParams.get("id") },
          //   contactDetails: { ...prevState.contactDetails, distributor_id: data.distributor_id, link_id: searchParams.get("id") },
          //   bankDetails: { ...prevState.bankDetails, distributor_id: data.distributor_id, link_id: searchParams.get("id") },
          //   coApplicantDetails: { ...prevState.coApplicantDetails, distributor_id: data.distributor_id, link_id: searchParams.get("id") },
          //   placementDetails: {
          //     ...prevState.placementDetails,
          //     distributor_id: data.distributor_id,
          //     placement_position_id: data.distributor_placement_mposition_id,
          //     placement_distributor_id: data.distributor_placement_distributor_id,
          //     link_id: searchParams.get("id"),
          //   },
          // }));
          let dataToPopulate = {
            distributor_id: data.distributor_id,
            sponsor_distributor_id: data.distributor_id,
            placement_position_id: data.distributor_placement_mposition_id,
            placement_distributor_id: data.distributor_placement_distributor_id,
            link_id: searchParams.get("id"),
          };
          if (data.dist_temp_id) {
            fetchEnrolleeDetails({ link_id: searchParams.get("id"), distributor_id: data?.distributor_id, dist_temp_id: data?.dist_temp_id }, (details) => {
              setCurrentPage(details.section_level);
              details.placement_position_id = data.distributor_placement_mposition_id;
              details.placement_distributor_id = data.distributor_placement_distributor_id;
              setDatatoEnrollForm(details, setEnrollmentDetails, searchParams.get("id"));
            });
          } else {
            setDatatoEnrollForm(dataToPopulate, setEnrollmentDetails, searchParams.get("id"));
          }
        },
        (error) => {
          SwalAlert.fire({
            icon: "error",
            title: "Uh Oh!",
            text: error,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
        }
      );
    } else {
      SwalAlert.fire({
        icon: "error",
        title: "Uh Oh!",
        text: `No ID found in the provided link. Please check the link you provided.`,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  }, []);

  useEffect(() => {
    if (enrollmentError) {
      WishToaster({ toastMessage: enrollmentError });
    }
  }, [enrollmentError]);

  useEffect(() => {
    console.log(enrollmentDetails, termsAgreed);
  }, [enrollmentDetails, termsAgreed]);

  useEffect(() => {
    if (termsAgreed) {
      acceptTerms({ link_id: searchParams.get("id"), distributor_id: enrollmentDetails.basicDetails.distributor_id }, (data) => {
        console.log(data);
      });
    }
  }, [termsAgreed]);

  const NavigationBar = () => {
    var currentProgress = ((currentPage + 1) / totalPages) * 100;
    return (
      <div className="row">
        {navigations.map((navigation, index) => {
          return (
            <div className="col" key={index}>
              <WishLinkCard
                key={index}
                linkTitle={navigation}
                background={
                  currentPage === index ? "bg-primary white text-white link-white box-shadow-3" : termsAgreed === true ? "bg-success white text-white link-white " : ""
                }
                onClick={() => {
                  if (canNavigate) {
                    validatePage(index);
                  }
                }}
              ></WishLinkCard>
            </div>
          );
        })}
        <div className="col-12">
          <div className="progress" style={{ height: "3px" }}>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: currentProgress + "%" }}
              aria-valuenow={currentProgress}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    );
  };

  const ErrorSection = () => {
    if (pageError) {
      return (
        <>
          <hr />
          <div className="text-danger">{pageError}</div>
          <small>
            <em>Correct the above error(s) to proceed further</em>
          </small>
        </>
      );
    }

    return <></>;
  };

  const validatePage = (navigateToPage = -1) => {
    let pageIsValid = true;

    if (isPageDirty) {
      switch (currentPage) {
        case 0:
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.first_name, "Please enter your first name", setPageError);

          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.second_name, "Please enter your last name", setPageError);

          pageIsValid = pageIsValid && ValidationUtils.validateDOB(enrollmentDetails.basicDetails?.dob, "Please enter date of birth", setPageError);

          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.aadhar_no, "Kindly enter a valid Aadhar Number", () => {}) ||
              ValidationUtils.isValid(enrollmentDetails.basicDetails?.aadhar_no, /^[2-9]\d{11}$/, "Kindly enter a valid Aadhar Number", setPageError));
          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.pan_no, "Kindly enter a valid PAN Number", () => {}) ||
              ValidationUtils.isValid(enrollmentDetails.basicDetails?.pan_no, /^[A-Z]{5}\d{4}[A-Z]$/, "Kindly enter a valid PAN Number", setPageError));
          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.basicDetails?.gst_no, "Kindly enter a valid GST Number", () => {}) ||
              ValidationUtils.isValid(
                enrollmentDetails.basicDetails?.gst_no,
                /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[A-Z0-9]{1}$/,
                "Kindly enter a valid GST Number",
                setPageError
              ));
          if (
            (enrollmentDetails.basicDetails.gst_no === "" && enrollmentDetails.basicDetails.gst_declaration === 0) ||
            (enrollmentDetails.basicDetails.pan_no === "" && enrollmentDetails.basicDetails.pan_declaration === 0)
          ) {
            AppUtils.showDialog("dlgConsent");
            pageIsValid = false;
          }

          break;

        case 1:
          pageIsValid =
            pageIsValid && ValidationUtils.isValid(enrollmentDetails.contactDetails?.phone, /^[6-9]\d{9}$/, "Please enter a valid phone number", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isValid(
              enrollmentDetails.contactDetails?.email,
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              "Please enter a valid email",
              setPageError
            );
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isEmpty(enrollmentDetails.contactDetails?.permanent_address_line1, "Please enter your permanent address - line 1", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isEmpty(enrollmentDetails.contactDetails?.permanent_address_line2, "Please enter your permanent address - line 2", setPageError);
          pageIsValid =
            pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.contactDetails?.permanent_postalcode_id.toString(), "Please enter pincode", setPageError);
          break;

        case 2:
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.bankDetails?.account_name, "Please enter your account name", setPageError);
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.bankDetails?.account_no, "Please enter your account number", setPageError);
          pageIsValid =
            pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.bankDetails?.bank_name, "Please select your bank by entering a valid IFSC code", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isSame(
              enrollmentDetails.bankDetails?.account_no,
              enrollmentDetails.bankDetails?.confirm_account_no,
              "Account numbers don't match",
              setPageError
            );
          break;

        case 3:
          pageIsValid = pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_name, "Please enter co-applicant name", setPageError);
          pageIsValid =
            pageIsValid && ValidationUtils.validateDOB(enrollmentDetails.coApplicantDetails?.coapplicant_dob, "Please enter co-applicant date of birth", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isValid(enrollmentDetails.coApplicantDetails?.coapplicant_phone, /^[6-9]\d{9}$/, "Enter a valid 10 digit phone number", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isValid(
              enrollmentDetails.coApplicantDetails?.coapplicant_email,
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              "Enter a valid email",
              setPageError
            );
          pageIsValid =
            pageIsValid &&
            (!ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_pan_no, "Kindly enter a valid PAN Number", () => {}) ||
              ValidationUtils.isValid(enrollmentDetails.coApplicantDetails?.coapplicant_pan_no, /^[A-Z]{5}\d{4}[A-Z]$/, "Kindly enter a valid PAN Number", setPageError));
          pageIsValid =
            pageIsValid && ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_account_name, "Enter co-applicant account name", setPageError);
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isSame(
              enrollmentDetails.coApplicantDetails?.coapplicant_account_no,
              enrollmentDetails.coApplicantDetails?.coapplicant_confirm_account_no,
              "Account numbers don't match",
              setPageError
            );
          pageIsValid =
            pageIsValid &&
            ValidationUtils.isEmpty(enrollmentDetails.coApplicantDetails?.coapplicant_bank_name, "Please select your bank by entering a valid IFSC code", setPageError);
          break;

        case 4:
          pageIsValid = true;
          break;

        default:
          pageIsValid = true;
          break;
      }
    }

    if (pageIsValid === true && navigateToPage > -1) {
      saveEnrolleeDetails(searchParams.get("id"), currentPage, enrollmentDetails, () => {
        setPageError(null);
        setCurrentPage(navigateToPage);
      });
    }
  };

  const enrollNewUser = () => {
    if (enrollmentDetails.placementDetails.placement_position_id && enrollmentDetails.placementDetails.placement_distributor_id) {
      saveEnrolleeDetails(searchParams.get("id"), currentPage, enrollmentDetails, () => {
        validatePostion(enrollmentDetails.placementDetails, (data) => {
          console.log(data);
          if (data.is_valid) {
            enrollUser(enrollmentDetails.placementDetails, (data) => {
              console.log(data);
              SwalAlert.fire({
                icon: "success",
                title: "Congratulations!",
                text: `You have successfuly enrolled ${data.name}`,
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then(() => {
                navigate("/mygeneology");
              });
            });
          } else {
          }
        });
      });
    } else {
      WishToaster({
        toastMessage: "Enter a valid ID and select position.",
        toastType: "error",
      });
    }
  };

  const PreviewPage = () => {
    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            <WishPreviewSection
              title={PageTitles[0]}
              onEditClicked={() => {
                setCurrentPage(0);
              }}
            >
              <tr>
                <td>Title</td>
                <td>
                  {
                    enrollmentMasterData?.titles?.find((item) => {
                      return item.id === enrollmentDetails.basicDetails.title_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>First Name</td>
                <td>{enrollmentDetails.basicDetails.first_name}</td>
              </tr>
              <tr>
                <td>Last Name</td>
                <td>{enrollmentDetails.basicDetails.second_name}</td>
              </tr>
              <tr>
                <td>Date of birth</td>
                <td>{new Date(enrollmentDetails.basicDetails.dob).toDateString()}</td>
              </tr>
              <tr>
                <td>Preferred Language</td>
                <td>
                  {
                    enrollmentMasterData?.languages?.find((item) => {
                      return item.id === enrollmentDetails.basicDetails.language_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>
                  {
                    enrollmentMasterData?.gender?.find((item) => {
                      return item.id === enrollmentDetails.basicDetails.gender_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>Maritial Status</td>
                <td>
                  {
                    enrollmentMasterData?.marital_status?.find((item) => {
                      return item.id === enrollmentDetails.basicDetails.marital_status_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>Profession</td>
                <td>
                  {
                    enrollmentMasterData?.profession?.find((item) => {
                      return item.id === enrollmentDetails.basicDetails.profession_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>Monthly Income</td>
                <td>
                  {
                    enrollmentMasterData?.monthly_income?.find((item) => {
                      return item.id === enrollmentDetails.basicDetails.monthly_income_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>Aadhar</td>
                <td>{enrollmentDetails.basicDetails.aadhar_no}</td>
              </tr>
              <tr>
                <td>GST Number</td>
                <td>{enrollmentDetails.basicDetails.gst_no}</td>
              </tr>
              <tr>
                <td>PAN</td>
                <td>{enrollmentDetails.basicDetails.pan_no}</td>
              </tr>
              <tr>
                <td>Aadhar Card (Attachment)</td>
                <td>
                  {enrollmentDetails.basicDetails.aadhar_file?.name ? (
                    <a href="javascript:void();">
                      <i className="las la-file-alt la-2x"></i>
                      {enrollmentDetails.basicDetails.aadhar_file?.name}
                    </a>
                  ) : (
                    "No attachment"
                  )}
                </td>
              </tr>
              <tr>
                <td>PAN Card (Attachment)</td>
                <td>
                  {enrollmentDetails.basicDetails.aadhar_file?.name ? (
                    <a href="javascript:void();">
                      <i className="las la-file-alt la-2x"></i>
                      {enrollmentDetails.basicDetails.pan_file?.name}
                    </a>
                  ) : (
                    "No attachment"
                  )}
                </td>
              </tr>
            </WishPreviewSection>
          </div>
          <div className="col-sm-6">
            <WishPreviewSection
              title={PageTitles[1]}
              onEditClicked={() => {
                setCurrentPage(1);
              }}
            >
              <tr>
                <td>Full Name</td>
                <td>{enrollmentDetails.basicDetails.first_name + " " + enrollmentDetails.basicDetails.second_name} </td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>{enrollmentDetails.contactDetails.phone}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{enrollmentDetails.contactDetails.email}</td>
              </tr>
              <tr>
                <td>Permanent Address</td>
                <td>
                  {enrollmentDetails?.contactDetails?.permanent_address_line1}
                  <br />
                  {enrollmentDetails?.contactDetails?.permanent_address_line2}
                  <br />
                  {enrollmentDetails?.contactDetails?.permanent_post_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_city_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_district_name}
                  <br />
                  {enrollmentDetails?.contactDetails?.permanent_state_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_country_name +
                    " " +
                    enrollmentDetails?.contactDetails?.permanent_postalcode}
                </td>
              </tr>
              <tr>
                <td>Communication Address</td>
                <td>
                  {enrollmentDetails?.contactDetails?.communication_address_line1}
                  <br />
                  {enrollmentDetails?.contactDetails?.communication_address_line2}
                  <br />
                  {enrollmentDetails?.contactDetails?.communication_post_name +
                    " " +
                    enrollmentDetails?.contactDetails?.communication_city_name +
                    " " +
                    enrollmentDetails?.contactDetails?.communication_district_name}
                  <br />
                  {enrollmentDetails?.contactDetails?.communication_state_name +
                    " " +
                    enrollmentDetails?.contactDetails?.communication_country_name +
                    " " +
                    enrollmentDetails?.contactDetails?.communication_postalcode}
                </td>
              </tr>
              <tr>
                <td>Permanent Address Proof (Attachment)</td>
                <td className="">
                  {enrollmentDetails.contactDetails.address_proof_file?.name ? (
                    <a href="javascript:void();">
                      <i className="las la-file-alt la-2x"></i>
                      {enrollmentDetails.contactDetails.address_proof_file?.name}
                    </a>
                  ) : (
                    "No attachment"
                  )}
                </td>
              </tr>
            </WishPreviewSection>
          </div>
          <div className="col-sm-6">
            <WishPreviewSection
              title={PageTitles[2]}
              onEditClicked={() => {
                setCurrentPage(2);
              }}
            >
              <tr>
                <td>Account Holder</td>
                <td>{enrollmentDetails.bankDetails.account_name}</td>
              </tr>
              <tr>
                <td>Account Number</td>
                <td>{enrollmentDetails.bankDetails.account_no}</td>
              </tr>
              <tr>
                <td>IFSC Code</td>
                <td>{enrollmentDetails.bankDetails.ifsc}</td>
              </tr>
              <tr>
                <td>Bank Name</td>
                <td>{enrollmentDetails.bankDetails.bank_name}</td>
              </tr>
              <tr>
                <td>Bank Branch</td>
                <td>{enrollmentDetails.bankDetails.branch_name}</td>
              </tr>
              <tr>
                <td>Bank Address Proof (Attachment)</td>
                <td className="">
                  {enrollmentDetails.bankDetails.bank_proof_file?.name ? (
                    <a href="javascript:void();">
                      <i className="las la-file-alt la-2x"></i>
                      {enrollmentDetails.bankDetails.bank_proof_file?.name}
                    </a>
                  ) : (
                    "No attachment"
                  )}
                </td>
              </tr>
            </WishPreviewSection>
          </div>
          <div className="col-sm-6">
            <WishPreviewSection
              title={PageTitles[3]}
              onEditClicked={() => {
                setCurrentPage(3);
              }}
            >
              <tr>
                <td>Co-Applicant Name</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_name}</td>
              </tr>
              <tr>
                <td>Co-Applicant Date of birth</td>
                <td>{new Date(enrollmentDetails.coApplicantDetails.coapplicant_dob).toDateString()}</td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_phone}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_email}</td>
              </tr>
              <tr>
                <td>Relationship</td>
                <td>
                  {
                    enrollmentMasterData?.relationship?.find((item) => {
                      return item.id === enrollmentDetails.coApplicantDetails.coapplicant_relationship_id;
                    })?.title_name
                  }
                </td>
              </tr>
              <tr>
                <td>Co-Applicant PAN</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_pan_no}</td>
              </tr>
              <tr>
                <td>PAN (Attachment)</td>
                <td className="">
                  {enrollmentDetails.coApplicantDetails.coapp_pan_file?.name ? (
                    <a href="javascript:void();">
                      <i className="las la-file-alt la-2x"></i>
                      {enrollmentDetails.coApplicantDetails.coapp_pan_file?.name}
                    </a>
                  ) : (
                    "No attachment"
                  )}
                </td>
              </tr>
              <tr>
                <td>Account Holder</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_account_name}</td>
              </tr>
              <tr>
                <td>Account Number</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_account_no}</td>
              </tr>
              <tr>
                <td>IFSC Code</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_ifsc}</td>
              </tr>
              <tr>
                <td>Bank Name</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_bank_name}</td>
              </tr>
              <tr>
                <td>Bank Branch</td>
                <td>{enrollmentDetails.coApplicantDetails.coapplicant_branch_name}</td>
              </tr>
              <tr>
                <td>Bank Address Proof (Attachment)</td>
                <td className="">
                  {enrollmentDetails.coApplicantDetails.coapp_bank_proof_file?.name ? (
                    <a href="javascript:void();">
                      <i className="las la-file-alt la-2x"></i>
                      {enrollmentDetails.coApplicantDetails.coapp_bank_proof_file?.name}
                    </a>
                  ) : (
                    "No attachment"
                  )}
                </td>
              </tr>
              <tr>
                <td>Nominee Name</td>
                <td>{enrollmentDetails.coApplicantDetails.nominee}</td>
              </tr>
              <tr>
                <td>Nominee Relationship</td>
                <td>
                  {
                    enrollmentMasterData?.relationship?.find((item) => {
                      return item.id === enrollmentDetails.coApplicantDetails.nominee_mrelationship_id;
                    })?.title_name
                  }
                </td>
              </tr>
            </WishPreviewSection>
          </div>
        </div>
        <div className="col-12">
          <div className="alert alert-warning d-flex justify-content-between align-items-center" role="alert">
            Terms and Conditions
            <a
              className="card-link link-dotted text-white"
              onClick={() => {
                downloadTerms({ link_id: searchParams.get("id"), distributor_id: enrollmentDetails.basicDetails.distributor_id });
              }}
            >
              Download (T&amp;C)
            </a>
          </div>
          <div className="form-group row">
            <div className="col-12">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input
                  name="chkTerms"
                  id="chkTerms_0"
                  type="checkbox"
                  className="custom-control-input"
                  checked={termsAgreed}
                  readOnly
                  onClick={() => {
                    setTermsAgreed(!termsAgreed);
                  }}
                />
                <label for="chkTerms_0" className="custom-control-label">
                  {terms[0]?.terms}
                </label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <PageLayout {...pageConfig.enrollUser}>
      <NavigationBar />
      <WishCarousel
        selectedPageIndex={currentPage}
        nextLinkTitle="PROCEED"
        showArrows
        showNextPrev={true}
        termsAgreed={termsAgreed}
        onNextClicked={() => {
          if (!canNavigate) setCanNavigate(true);
          validatePage(currentPage + 1);
        }}
        onPreviousClicked={() => {
          if (!canNavigate) setCanNavigate(true);
          validatePage(currentPage - 1);
        }}
        onFinishClicked={() => {
          console.log("finish clicked");
          enrollNewUser();
        }}
        title={<h5>{PageTitles[currentPage]}</h5>}
      >
        {
          <>
            {enrollmentLoading ? <LoadingNote /> : null}
            <BasicDetailsPage
              enrollmentMasterData={enrollmentMasterData}
              setIsPageDirty={setIsPageDirty}
              ErrorSection={ErrorSection}
              enrollmentDetails={enrollmentDetails}
              setEnrollmentDetails={setEnrollmentDetails}
            />
          </>
        }
        {
          <>
            {enrollmentLoading ? <LoadingNote /> : null}
            <ContactDetailsPage
              enrollmentMasterData={enrollmentMasterData}
              setIsPageDirty={setIsPageDirty}
              ErrorSection={ErrorSection}
              enrollmentDetails={enrollmentDetails}
              setEnrollmentDetails={setEnrollmentDetails}
            />
          </>
        }
        {
          <>
            {enrollmentLoading ? <LoadingNote /> : null}
            <BankDetailsPage
              setIsPageDirty={setIsPageDirty}
              ErrorSection={ErrorSection}
              enrollmentDetails={enrollmentDetails}
              setEnrollmentDetails={setEnrollmentDetails}
            />
          </>
        }
        {
          <>
            {enrollmentLoading ? <LoadingNote /> : null}
            <CoApplicantDetailsPage
              enrollmentMasterData={enrollmentMasterData}
              setIsPageDirty={setIsPageDirty}
              ErrorSection={ErrorSection}
              enrollmentDetails={enrollmentDetails}
              setEnrollmentDetails={setEnrollmentDetails}
            />
          </>
        }
        {
          <>
            {enrollmentLoading ? <LoadingNote /> : null}
            <PreviewPage />
          </>
        }
      </WishCarousel>
    </PageLayout>
  );
};

export default EnrollUser;
